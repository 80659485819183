const mutations = {
    SET_TOKEN: (state, payload) =>  state.token = payload,
    RESET_TOKEN: (state) => state.token = undefined,
    SET_USER: (state, payload) => state.user = payload,
    RESET_USER: (state) => state.user = undefined,
    REMOVE_FAVOURITE: (state, payload) => state.user = {...state.user, favourites: state.user.favourites.filter(ele => !(ele.courseId == payload.courseId && ele.lessonId == payload.lessonId))},
    ADD_FAVOURITE: (state, payload) => state.user = {...state.user, favourites: [...state.user.favourites, payload] },
}

export default mutations;
