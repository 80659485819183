<template>
    <navbar />
  <div class="container-fluid d-flex flex-column h-100">
    <div class="row mt-5 p-5">
      <div class="col-12">
        <TitleH1 className="text-center" label="Gdpr"></TitleH1>
        <p class="text-uppercase text-center mt-2">
          <router-link to="/register">REGISTRAZIONE</router-link>
        </p>
      </div>
    </div>
    <div class="row px-md-5 px-2 pb-5 pt-5 bgGrayLight">
      <p id="isPasted"><strong>MODULO ISCRIZIONE PROGRAMMA</strong><br /><br /><br />Con la presentazione di questo Modulo chiedo di essere iscritto al Programma di Fidelizzazione &ldquo;Circolo delle Onicotecniche&rdquo; secondo le condizioni stabilite nel Regolamento, che dichiaro di aver letto ed approvare interamente.</p>
      <p>Presa d&rsquo;atto dell&rsquo;Informativa Privacy ed eventuale consenso per finalit&agrave; ulteriori.</p>
      <p>Dichiaro altres&igrave; di aver preso visione dell&rsquo;Informativa Privacy relativa al trattamento dei dati personali necessari per l&rsquo;iscrizione al Programma di Fidelizzazione.</p>
      <p>Presto in particolare il mio consenso per l&rsquo;attivit&agrave; di profilazione con CHECK BOX</p>
      <p><strong>REGOLAMENTO PROGRAMMA &ldquo;CIRCOLO DELLE ONICOTECNICHE&rdquo;</strong></p>
      <p>&nbsp;</p>
      <p><strong>1. OGGETTO E CARATTERISTICHE</strong></p>
      <p>Compilando il Modulo di Iscrizione il Cliente chiede ad Essepro s.r.l. (di seguito &ldquo;Essepro&rdquo;) di aderire al programma denominato &ldquo;Circolo delle Onicotecniche&rdquo; (di seguito per semplicit&agrave; &ldquo;Programma&rdquo;) per acquisire la qualifica di &ldquo;Cliente Fidelizzato&rdquo;.</p>
      <p>Il Programma &egrave; volto a premiare la fedelt&agrave; del Cliente con una serie di iniziative promozionali dedicate al Cliente Fidelizzato secondo le condizioni del presente Regolamento (&ldquo;Vantaggi&rdquo;).</p>
      <p>I singoli Vantaggi di volta in volta previsti dal Programma saranno comunicati da Essepro nelle forme commerciali ritenute pi&ugrave; idonee per la migliore conoscibilit&agrave; da parte dei Clienti Fidelizzati, in particolare all&rsquo;interno della App del Circolo delle Onicotecniche, o tramite email.<br /><br /></p>
      <p><strong>2. ADESIONE</strong></p>
      <p>La previa lettura ed approvazione del Regolamento e dell&rsquo;Informativa privacy sono necessarie per l&rsquo;iscrizione al Programma. Il Cliente Fidelizzato dichiara di accettare il presente Regolamento mediante l&rsquo;invio del Modulo di iscrizione o comunque mediante la compilazione dello stesso.</p>
      <p>L&rsquo;adesione al Programma si perfezioner&agrave; ed avr&agrave; efficacia dal momento in cui Essepro, a seguito della richiesta ricevuta, invier&agrave; al Cliente la mail di conferma. Da questo momento il Cliente &egrave; iscritto al Programma e pu&ograve; usufruire dei relativi Vantaggi.</p>
      <p>Ogni modifica del presente Regolamento si intende approvata dal Cliente Fidelizzato al momento dell&rsquo;usufruizione dei Vantaggi successivamente alla comunicazione delle modifiche stesse.<br /><br /></p>
      <p><strong>3. VANTAGGI CONFERITI AGLI ISCRITTI AL PROGRAMMA</strong></p>
      <p>L&rsquo;iscrizione al Programma conferisce al Cliente Fidelizzato i seguenti Vantaggi:</p>
      <p>-possibilit&agrave; di essere informato e conoscere con anticipo le iniziative promozionali e pubblicitarie di Essepro;</p>
      <p>- possibilit&agrave; di usufruire di agevolazioni sui prodotti venduti da Essepro, che verranno espressamente comunicati con l&rsquo;espressione &ldquo;in corso&rdquo; o altra equipollente. In nessun caso i Vantaggi riservati ai Clienti Fidelizzati potranno essere cumulati con eventuali altri vantaggi (sconti, promozioni e simili) gi&agrave; applicati sui medesimi prodotti in base a previsioni diverse dal Programma;<br /><br /></p>
      <p>- possibilit&agrave; di accedere a contenuti formativi, quali tutorial, corsi di formazione, tecniche di vendita, tecniche di utilizzo e simili inerenti ai prodotti venduti da Essepro;<br /><br /></p>
      <p>-possibilit&agrave; di ricevere informazioni commerciali e promozioni personalizzate e dedicate rispetto alle sue esigenze e alle sue preferenze;</p>
      <p>- possibilit&agrave; di richiedere assistenza da parte di Essepro in relazione all&rsquo;utilizzo dei prodotti, attraverso canali dedicati.</p>
      <p><strong>4. DURATA</strong></p>
      <p>La partecipazione al Programma ha durata fino a revoca da parte del Cliente Fidelizzato, da comunicare ad Essepro a mezzo mail all&rsquo;indirizzo&nbsp;app@pronails.it</p>
      <p>In caso di cancellazione del profilo tutti i relativi dati verranno cancellati.</p>
      <p><strong>5. INTERRUZIONE DEL RAPPORTO DI FIDELIZZAZIONE</strong></p>
      <p>Essepro si riserva il diritto di inibire l&rsquo;esercizio dei Vantaggi previsti dal Programma in caso di violazione del presente Regolamento ovvero in caso di qualsivoglia utilizzo abusivo ovvero lesivo di Essepro e dei propri diritti.</p>

    </div>
  </div>
</template>

<script>
import Navbar from "@/components/core/Navbar";
import TitleH1 from "@/components/shared/design/TitleH1";
export default {
  name: "Gdpr",
  components: {TitleH1, Navbar}
}
</script>

<style scoped>

</style>
