<template>
  <LoaderFullScreen :show="isLoading" />
  <ErrorMessage />
  <router-view/>



</template>

<style lang="scss">

@import "sass/index";

</style>
<script>
import ErrorMessage from "./components/core/ErrorMessage";
import LoaderFullScreen from "@/components/shared/design/loader/LoaderFullScreen";
export default {
  components: {LoaderFullScreen, ErrorMessage},
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
  computed: {
    isLoading() {
      return !!this.$store.getters.getCallNumber;
    }
  }
}
</script>
