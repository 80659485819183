<template>
  <div class="pt-5 mt-5 px-md-5 px-2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 col-lg-6">

         <div class="mb-3">
           <TitleH1 label="Ordinamento Corsi" />
           <draggable data-id="ordinamento"
               group="courses"
               :list="courses"
               @change="log"
               @start="drag=true"
               @end="onEnd"
               item-key="_id">
             <template #item="{element}">
               <div class="draggableItem"><i class="bi bi-grip-horizontal me-3"></i>{{ element.name }}</div>
             </template>
           </draggable>

          <div class="mt-5 ">
             <ButtonPrimary label="Salva" @click="saveOrder"></ButtonPrimary>


          </div>
           <div class="mt-2 ">
           <icon-button label="Chiudi" icon="bi bi-x-circle" @click="$emit('closeSort')"/>
           </div>
         </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>


import {coursesIndex, courseSort, listAllLesson} from "../../../services/coursesService";
import draggable from "vuedraggable"
import Accordion from "../../../components/shared/design/Accordion";
import ButtonPrimary from "@/components/shared/design/ButtonPrimary";
import TitleH1 from "@/components/shared/design/TitleH1";
import IconButton from "@/components/shared/design/iconButton";
import {downloadImage} from "@/firebase/client";

export default {
  name: 'ListAllCourses',
  components: {IconButton, TitleH1, ButtonPrimary, Accordion, draggable},
  data() {
    return {
      courses: [],
      drag: false,
      addedData: undefined,
      removedData: undefined
    }
  },
  methods: {
    log(evt) {

      if (evt.moved) {
        const element = this.courses.find(e => e._id === evt.moved.element._id);
        const allCourses = this.courses.filter(ele => ele._id !== evt.moved.element._id);
        this.courses = [
          ...allCourses.slice(0, evt.moved.newIndex),
          element,
          ...allCourses.slice(evt.moved.newIndex)
        ];
      }



    },
    onEnd(ev){
      this.drag= false

          },
    saveOrder(){
      courseSort({courses: this.courses}).then(r => {

      })

    }
  },
  mounted() {
    coursesIndex().then(res => {
      const promises = res.map(ele => new Promise((resolve) => {
        downloadImage('courses/'+ele._id).then(images =>{

          const image = images.length ? images[0].path : ele.image


          resolve ({...ele, image})

        } )
      }))
      Promise.all(promises).then(res =>{
        this.courses = res;
      })

    })

  }
}
</script>

<style lang="scss" scoped>
@import "src/sass/variables";
.draggableItem{
  padding: 12px;
  border: 1px solid $dark;
  margin-bottom: 6px;
  cursor: grab;
  &:hover{
    background: rgba($dark, 0.2);
  }
}
</style>
