<template>
  <button class="btn btn-primary mt-3 me-3 text-white" @click="$emit('buttonClick')" type="submit"><i :class="icon"></i>
  </button>
</template>





<script>
export default {
  name: "FavouriteButton",
  props: {
    class: {type: String},
    icon: {type: String},
    type: {type: String, default: 'submit'},
  },
}
</script>

<style lang="scss" scoped>
@import "src/sass/variables";
.btn-primary{
  background-color: $primary;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  color: $dark;
  border-radius: $borderRadius;
  padding: 8px 14px;
}
</style>
