import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import axios from "axios";
import AuthInterceptorRequest from "./interceptors/AuthInterceptorRequest";
import AuthInterceptorResponse from "./interceptors/AuthInterceptorResponse";
import successInterceptor from "./interceptors/successInterceptor";
import errorInterceptor from "./interceptors/errorInterceptor";
import VueQuillEditor from "vue-quill-editor/src";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


axios.interceptors.request.use((config) => {
    store.dispatch('incrementNumber');
    return config;
});
axios.interceptors.response.use(
    (config) => {
        store.dispatch('decrementNumber');
        return config;
    },
    (err) => {
        store.dispatch('decrementNumber');
        return Promise.reject(err);
    }
);
axios.interceptors.request.use(AuthInterceptorRequest)
axios.interceptors.response.use((config)=> config,  AuthInterceptorResponse)
axios.interceptors.response.use(successInterceptor,  errorInterceptor)
createApp(App).use(store).use(router).use(VueQuillEditor).mount('#app')
