// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCkcbv5SJYaLlmmhMqQ5O21PYXgB9eudxU",
    authDomain: "circolodelleonicotecniche.firebaseapp.com",
    databaseURL: "https://circolodelleonicotecniche-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "circolodelleonicotecniche",
    storageBucket: "circolodelleonicotecniche.appspot.com",
    messagingSenderId: "863435949429",
    appId: "1:863435949429:web:896c984cb5891611283f3c"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app, process.env.VUE_APP_FIREBASE_GSE);