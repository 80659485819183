<template>


  <div v-if="show">
  <div class="loaderFullScreen">
   <Loader/>


  </div>

  </div>


</template>

<script>

import Loader from "@/components/shared/design/loader/Loader";

export default {
  name: "LoaderFullScreen",
  components: {Loader},

  props: {
    show: {
      type: Boolean,
      default: true
    }

  }
}
</script>

<style lang="scss" scoped>
.loaderFullScreen {
  background-color: #FFFFFFC8;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 5000;
}
</style>
