const actions = {
    setBackPage: (context, payload) => {
        context.commit('SET_BACKPAGE', payload)
    },
    resetBackPage: (context, payload) => {
    context.commit('RESET_BACKPAGE', payload)
    },
    incrementNumber: (context, payload) => {
        context.commit('INCREMENT_NUMBER')
    },
    decrementNumber: (context, payload) => {
        context.commit('DECREMENT_NUMBER')
    }
}

export default actions
