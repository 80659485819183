import {ref, uploadBytes, getDownloadURL, listAll, deleteObject} from "firebase/storage"
import {storage} from "../../firebaseConfig";

export const uploadImage = (file, basePath = 'general') => {
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage, process.env.VUE_APP_BASE_IMG_DIR+'/'+basePath+'/'+file.name)
        uploadBytes(storageRef, file).then((snapshot) => {
            resolve(true)
        })
    })

}

export const downloadImage = (basePath = 'general') => {
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage, process.env.VUE_APP_FIREBASE_GS+'/'+process.env.VUE_APP_BASE_IMG_DIR+'/'+basePath)
        const list= [];
        listAll(storageRef).then(res => {
            res.items.forEach(itemRef => {
                list.push({path: getDownloadURL(itemRef), name: itemRef.name, itemRef})

            })
            Promise.all(list.map(ele => ele.path)).then(res => {
                resolve(res.map((ele, index) => ({
                    path: ele,
                    name: list[index].name ,
                    itemRef:  list[index].itemRef
                })))
            })
        })

    })

}
export const deleteImage = (downloaded =[], basePath) => {


        const promises = downloaded.map(image => {

        const storageRef = ref(storage, process.env.VUE_APP_FIREBASE_GS+'/'+process.env.VUE_APP_BASE_IMG_DIR+'/'+basePath+'/'+image.name)

           return deleteObject(storageRef)
        }
        )
            return Promise.all(promises)
}


