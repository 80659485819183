import router from "../../router";

const actions = {
    setToken: (context, payload) => {
        context.commit('SET_TOKEN', payload)
    },
    resetToken: (context) => {
        context.commit('RESET_TOKEN')
    },
    setUser: (context, payload) => {
        context.commit('SET_USER', payload)
    },
    resetUser: (context) =>{
        context.commit('RESET_USER')
    },
    removeFavourite:(context, payload) => {
        context.commit('REMOVE_FAVOURITE', payload)
    },
    addFavourite:(context, payload) => {
        context.commit('ADD_FAVOURITE', payload)
    },
    toggleFavourite:(context, payload) => {
    if(context.state.user.favourites.find(ele => ele.courseId == payload.courseId && ele.lessonId == payload.lessonId)){
                context.dispatch('removeFavourite', payload)
            }else{
                context.dispatch('addFavourite', payload)
            }

    },
    logout: (context) =>{
        localStorage.removeItem('token');
        context.dispatch("resetToken");
        context.dispatch("resetUser");
        router.push({name: 'Login'});
    }
}

export default actions
