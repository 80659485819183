<template>
  <navbar/>

  <div class="container-fluid d-flex flex-column h-100">
    <div class="row mt-5 p-5">
      <div class="col-12">
        <TitleH1 className="text-center" label="Registrati"></TitleH1>
      </div>
    </div>
    <div class="row px-md-5 px-2 pb-5 pt-5 bgGrayLight h-100">

      <div class="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 ">
        <div class="formCustomLogin">

          <form @submit.prevent="userRegister">
            <div class="row">
              <FormGroupCustom :noLabel="true" :error="errors['name']" v-model:value="user.name" label="nome"
                               type="text"></FormGroupCustom>
              <FormGroupCustom :noLabel="true" :error="errors['email']" v-model:value="user.email" label="email"
                               type="text"></FormGroupCustom>
              <div class="form-check">
                <FormGroupCustom :error="errors['privacy']" v-model:value="user.privacy"
                                 label="Dichiaro di aver letto e preso atto dell’informativa privacy relativa al programma"
                                 type="checkbox"></FormGroupCustom>
              </div>
              <div class="form-check">
                <FormGroupCustom v-model:value="user.gdpr"
                                 label="Accetto di ricevere sconti a me dedicati e promozioni attraverso la mia profilazione"
                                 type="checkbox"></FormGroupCustom>
              </div>
              <div class="col-12 mt-4">
                <ButtonPrimary class="w-100" label="registrati"></ButtonPrimary>
              </div>
            </div>
          </form>
          <p class="text-uppercase text-center mt-2">
            <router-link to="/login">Sei già registrato?</router-link>
          </p>
          <div class="mt-5">
            <p class="text-uppercase text-center mt-2">
              <router-link to="/gdpr">GDPR</router-link>
           |
              <router-link to="/privacy">PRIVACY</router-link>
            </p>
          </div>

        </div>

      </div>

    </div>


  </div>


</template>

<script>

import FormGroupCustom from "../components/shared/form/FormGroupCustom";
import {validationMixin} from "../mixins/validationMixin";
import {validationTypeName} from "../utils/validationType";
import {http} from "../utils/http";
import Navbar from "../components/core/Navbar";
import lastRoute from "../guards/lastRoute";
import TitleH1 from "../components/shared/design/TitleH1";
import ButtonPrimary from "../components/shared/design/ButtonPrimary";

export default {
  data() {
    return {
      user: {},
      privacy: false,
      gdpr: false,
      validazione: [
        {
          name: 'email',
          validation: {
            type: validationTypeName.required,
          }
        },
        {
          name: 'email',
          validation:
              {type: validationTypeName.email}
        },
        {
          name: 'name',
          validation: {
            type: validationTypeName.required,
          }
        },
        {
          name: 'privacy',
          validation: {
            type: validationTypeName.required,
          }
        },
        {
          name: 'privacy',
          validation: {
            type: validationTypeName.privacy,
          }
        }
      ]

    }
  },
  name: "Register",
  mixins: [validationMixin],
  components: {ButtonPrimary, TitleH1, Navbar, FormGroupCustom},

  methods: {
    userRegister() {
      this.$store.dispatch('resetErrors');
      if (this.checkLogin) {

        http.post(process.env.VUE_APP_URL + '/register-out', this.user).then(res => {
          this.$router.push(lastRoute.route ? {
            name: lastRoute.route.name,
            params: lastRoute.route.params
          } : {name: 'Login'})
        })
      }
    },
    enablePrivacy() {
      this.privacy === true ? this.privacy = false : this.privacy = true;
    },
    enableGdpr() {
      this.gdpr === true ? this.gdpr = false : this.gdpr = true;
    }
  },

  computed: {
    allValidations() {
      return [...this.validazione]

    },
    checkLogin() {
      return this.isValid(this.user)
    }
  }

}


</script>

<style lang="scss" scoped>
.form-group {
  text-transform: none;
}
</style>
