<template>
    <navbar />
  <div class="container-fluid d-flex flex-column h-100">
    <div class="row mt-5 p-5">
      <div class="col-12">
        <TitleH1 className="text-center" label="Privacy"></TitleH1>
        <p class="text-uppercase text-center mt-2">
          <router-link to="/register">REGISTRAZIONE</router-link>
        </p>
      </div>
    </div>
    <div class="row px-md-5 px-2 pb-5 pt-5 bgGrayLight">
      <p id="isPasted"><strong>Informativa Privacy (artt. 13 Reg. UE 2016/679)</strong></p>
      <p>Gentile Cliente Fidelizzato,</p>
      <p>ai fini dell&rsquo;instaurazione e della gestione del rapporto di fidelizzazione, in qualit&agrave; di Titolare del trattamento dei Suoi dati personali Le rendiamo la presente informativa ai sensi e per gli effetti degli art. 13 Regolamento UE 2016/679, nonch&eacute; della normativa italiana allo stato vigente.<br /><br /><br /></p>
      <p><strong>Titolare del trattamento</strong></p>
      <p>Titolare del trattamento &egrave; la societ&agrave; Essepro s.r.l., con sede legale in 35136 Padova (PD), Via Thomas Edison n. 38, C.F. e P.IVA 04807160280 &ndash; email&nbsp;app@pronails.it<br /><br /></p>
      <p><strong>Responsabile della protezione dei dati</strong></p>
      <p>Cervato Law &amp; Business S.R.L. Società Tra Avvocati, nella persona dell&rsquo;Avv. Piergiovanni Cervato &ndash; Galleria Europa 3c- 35137 Padova &ndash;&nbsp;dpo.essepro@cervato.it.<br /><br /></p>
      <p><strong>Finalit&agrave; del trattamento</strong></p>
      <p>Il trattamento &egrave; finalizzato all&rsquo; instaurazione e gestione del rapporto di fidelizzazione e in particolare;</p>
      <p>1. svolgimento di attivit&agrave; relative, connesse e funzionali all&rsquo;iscrizione del Cliente Fidelizzato al Programma di fidelizzazione secondo i modi e i termini descritti nel Regolamento (per esempio finalit&agrave; contrattuali, fiscali e simili);</p>
      <p>2. svolgimento di attivit&agrave; di marketing strettamente inerenti al Programma, mediante l&rsquo;invio, a mezzo e-mail, di informazioni e comunicazioni di natura promozionale e pubblicitaria relative e connesse ai vantaggi usufruibili dal Cliente Fidelizzato;</p>
      <p>3. svolgimento di attivit&agrave; di profilazione strettamente inerente il Programma e dedicata all&rsquo;offerta di promozioni e offerte dedicate espressamente alle esigenze del Cliente Fidelizzato;</p>
      <p>4. soft spam</p>
      <p>Il trattamento potr&agrave; avvenire con modalit&agrave; elettroniche ed anche in forma cartacea.<br /><br /></p>
      <p><strong>Tipologie di dati e fonti di origine</strong></p>
      <p>I dati trattati per le finalit&agrave; suddette sono dati personali comuni identificativi. I dati provengono dal conferimento volontario dell&rsquo;Interessato.<br /><br /></p>
      <p><strong>Base giuridica del trattamento</strong></p>
      <p>- art. 6, comma 1, lett. a) GDPR = CONSENSO espresso dell&rsquo;Interessato per la finalit&agrave; di profilazione di cui al n. 3;</p>
      <p>- art. 6, par. 1, lett. b) GDPR = ESECUZIONE DI MISURE PRECONTRATTUALI O CONTRATTUALI per le finalit&agrave; di cui sopra, relativamente al trattamento dei dati necessario all&rsquo;esecuzione delle prestazioni oggetto del Programma da parte del Titolare;</p>
      <p>- art. 6, comma 1, lett. c) GDPR = ADEMPIMENTO DI UN OBBLIGO LEGALE per le finalit&agrave; di cui sopra, relativamente al trattamento necessario per adempiere un obbligo legale del Titolare, ad esempio per gli adempimenti fiscali, legali o altri simili cui &egrave; soggetto il Titolare;</p>
      <p>- art. 6, par. 1, lett. f) GDPR = LEGITTIMO INTERESSE DEL TITOLARE per tutte le finalit&agrave; di cui sopra, svolte nel perseguimento dei fini istituzionali e statutari del Titolare; a tale fine il legittimo interesse &egrave; costituito dal perseguimento di tali fini istituzionali e statutari, a condizione che non prevalgano gli interessi o i diritti e le libert&agrave; fondamentali dell'Interessato che richiedono la protezione dei dati personali.<br /><br /></p>
      <p><strong>Obbligatoriet&agrave; del conferimento e conseguenze del rifiuto a fornire i dati</strong></p>
      <p>Il conferimento dei dati &egrave; obbligatorio per le finalit&agrave; di cui ai punti 1. e 2. e pertanto l'eventuale rifiuto a fornirli pu&ograve; dar luogo all'impossibilit&agrave; per il Titolare di dare esecuzione al contratto e impedisce l&rsquo;iscrizione al Programma di fidelizzazione. Il conferimento dei dati &egrave; facoltativo per le finalit&agrave; di cui al punto 3. e comporta l&rsquo;eventuale impossibilit&agrave; per il Titolare di inviare al Cliente fidelizzato offerte personalizzate in base alle proprie esigenze.<br /><br /></p>
      <p><strong>Eventuali destinatari dei dati personali</strong></p>
      <p>I dati potranno essere comunicati ad Incaricati autorizzati al trattamento, a Responsabili del trattamento, soggetti collegati al Titolare da rapporti di collaborazione, a societ&agrave; collegate con il Titolare, a professionisti e consulenti per la resa di prestazioni accessorie a quelle oggetto del rapporto con l&rsquo;Interessato, ovvero in ogni caso anche a soggetti terzi che operano, anche in nome e conto del Titolare, per l&rsquo;evasione delle prestazioni connesse alle finalit&agrave; indicate nella presente informativa, sia intra UE che extra UE (in tale ultimo caso il Titolare svolger&agrave; tutti i necessari adempimenti vigenti).<br /><br /></p>
      <p><strong>Periodo di conservazione</strong></p>
      <p>I dati saranno conservati per tutta la durata del rapporto ed in ogni caso fino alla scadenza del termine di prescrizione per l&rsquo;esercizio dei diritti.<br /><br /></p>
      <p><strong>Diritti dell&rsquo;Interessato (artt. 15 &ndash; 22 GDPR)</strong></p>
      <p>Ai sensi degli artt. da 15 a 22 del GDPR ciascun Interessato ha diritto di accesso, di rettifica, di cancellazione (oblio), di limitazione, di ricezione della notifica in caso di rettifica, cancellazione o limitazione, di portabilit&agrave;, di opposizione e di non essere oggetto di una decisione individuale automatizzata, compresa la profilazione.</p>
      <p>Tali diritti possono essere esercitati nelle forme e nei termini di cui all&rsquo;art. 12 GDPR, mediante comunicazione scritta inviata al Titolare via e-mail all&rsquo;indirizzo&nbsp;app@pronails.it.</p>
      <p>Il Titolare render&agrave; risposta adeguata al pi&ugrave; presto e comunque entro il termine di 1 mese dalla ricezione della richiesta.<br /><br /></p>
      <p><strong>Diritto di revoca del consenso</strong></p>
      <p>L&rsquo;Interessato pu&ograve; in qualsiasi momento revocare il presente consenso tramite</p>
      <p>1. invio di una mail all&rsquo;indirizzo del Titolare&nbsp;app@essepro.it</p>
      <p>2. disiscrivendosi dal modulo di mail automatica attraverso i link inviati dall&rsquo;indirizzo&nbsp;info@pronails.it</p>
      <p>&nbsp;</p>
      <p><strong>Reclami</strong></p>
      <p>Ciascun Interessato ha diritto di proporre reclamo ai sensi degli artt. 77 e seguenti del GDPR ad un&rsquo;autorit&agrave; di controllo, che per lo Stato italiano &egrave; individuato nel Garante per la protezione dei dati personali. Le forme, le modalit&agrave; ed i termini di proposizione delle azioni di reclamo sono previste e disciplinate dalla legislazione nazionale vigente. Il reclamo fa salve le azioni amministrative e giurisdizionali, che per lo Stato italiano possono proporsi alternativamente al medesimo Garante o al Tribunale competente.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p id="isPasted">ProNails Italia || Essepro s.r.l. a socio unico || Cap. Soc. 10.000 &euro; i.v. || REA: PD - 419956 || P.iva-C.F. 04807160280</p>
      <p>Via T.A. Edison 38. 35136, Padova (PD) || Tel: +39 049.872.1.872 || mail:&nbsp;info@pronails.it&nbsp;|| pec:&nbsp;essepro@pec.it</p>
      <p>Cap. Soc. 10.000 &euro; i.v. || REA: PD - 419956 || pec:&nbsp;essepro@pec.it</p>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/core/Navbar";
import TitleH1 from "@/components/shared/design/TitleH1";
export default {
  name: "Gdpr",
  components: {TitleH1, Navbar}
}
</script>

<style scoped>

</style>
