<template>
  <div class="mt-5 pt-5 container-fluid">
    <div class="row">
      <div class="col-12 px-md-5 px-2">
        <TitleH1 label="Profilo Personale"/>
        <div>
          <p>Nome: {{ user.name }}</p>
          <p>Email: {{ user.email }}</p>

          <span @click="deleteUser" class="deleteFont">CANCELLA IL TUO ACCOUNT</span>
        </div>
        <div class="text-start mt-5 mb-5 btnProfile d-flex flex-wrap">
          <ButtonPrimary label="I Miei Corsi" @click="getCourse"></ButtonPrimary>


          <ButtonPrimary label="Video Preferiti" @click="getFavourite"></ButtonPrimary>


          <ButtonPrimary label="Assistenza Tecnica" @click="getSupport"></ButtonPrimary>

        <ButtonPrimary label="Reset Password" @click.prevent="resetPassword"/>
        <ButtonPrimary label="Ricarica Permessi" @click.prevent="reloadAuth"/>
          </div>
       <div class="d-flex justify-content-end" > <router-link @click.prevent="logout" to="/logout">Logout</router-link></div>


      </div>
    </div>
  </div>
</template>

<script>


import TitleH1 from "../../../components/shared/design/TitleH1";
import TitleH2 from "../../../components/shared/design/TitleH2";
import ButtonPrimary from "../../../components/shared/design/ButtonPrimary";
import {deleteAccountCall, getSupportCall, passwordResetCall, userDel} from "../../../services/userService";
import {http} from "@/utils/http";
import store from "@/store";

export default {
  name: 'Profile',
  components: {ButtonPrimary, TitleH2, TitleH1},
  methods: {
    getCourse() {
      this.$router.push({name: "Home"});
    },
    getFavourite() {
      this.$router.push({name: "Favourites"});
    },
    logout() {
      this.$store.dispatch('logout')
    },
    resetPassword() {
      passwordResetCall({email: this.user.email}).then(res => {
        this.logout();
      })
    },
    getSupport(){
      getSupportCall().then(res => {
        console.log(res)
      })

    },
    deleteUser(){
      if(confirm('Vuoi rimuovere il tuo profilo?')){
        if(confirm('Confermando il tuo profilo sarà eliminato definitivamente')){
          deleteAccountCall(this.user._id).then(res =>{
            this.logout();
          })
        }
      }
    },
    reloadAuth(){

      http.get('/auth').then(res =>{
        store.dispatch('setUser', res)
        this.$router.push({name: "Home"});
      })

    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.$store.dispatch('setBackPage', {name: 'Home'})
  },
  beforeUnmount() {
    this.$store.dispatch('resetBackPage')
  },
}
</script>
<style lang="scss" scoped>
.btnProfile{
  gap: 12px;
}
.deleteFont{
  color: red;
  cursor: pointer;
}
</style>
