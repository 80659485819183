<template>

<div class="mt-2 mb-2">
  <quill-editor :value="value" @change="onTextChange">

  </quill-editor>
<!--        <editor :apiKey="apiTiny" v-model="value"-->
<!--                :init="{-->
<!--           height: 300,-->
<!--           menubar: false,-->
<!--           plugins: [-->
<!--             'advlist autolink lists link image charmap print preview anchor',-->
<!--             'searchreplace visualblocks code fullscreen',-->
<!--             'insertdatetime media table paste code help wordcount'-->
<!--           ],-->
<!--           toolbar:-->
<!--             'undo redo | formatselect | bold italic backcolor | \-->
<!--             alignleft aligncenter alignright alignjustify | code \-->
<!--             bullist numlist outdent indent | removeformat | help'-->
<!--         }"-->
<!--        />-->

</div>
</template>

<script>
export default {
  name: "EditorTextArea",

  props: ["dataValue"],
  data(){
    return{
      value: ''
    }
  },
  watch: {
    dataValue(){

      this.value = this.dataValue || ''
    },
    value(){

      this.$emit("update:dataValue", this.value)
    }
  },
  methods: {
    onTextChange(value){
      this.value = value.html
    }
  }
}
</script>

<style scoped>

</style>