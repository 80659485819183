<template>
<div v-for="item in listFavourite">

</div>

    <CourseItems v-for="item in listFavourite"  :course="item" @goToCourse="goToCourse(item._id, item.lessons._id)"/>



</template>
<script>

import CourseItems from "@/components/shared/design/CourseItems";
export default {
  name: "ListFavourites",
  components: {CourseItems},
  props: {
    listFavourite : {type: Array , default: [] }
  },
  methods : {
    goToCourse(idCourse, idLesson) {
      this.$router.push({
        name: 'SingleCourseFilterWithLesson',
        params: {
          id: idCourse,
          idLesson: idLesson
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>


</style>
