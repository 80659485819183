<template>
  <div class="mt-5 pt-5 container-fluid">
    <div class="row">
      <div class="col-12 px-md-5 px-2">
        <TitleH1 label="Video Salvati"/>
        <ListFavourites :listFavourite="listAllFavourite"></ListFavourites>



      </div>
    </div>
  </div>
</template>

<script>


import TitleH1 from "../../../components/shared/design/TitleH1";
import TitleH2 from "../../../components/shared/design/TitleH2";
import ButtonPrimary from "../../../components/shared/design/ButtonPrimary";
import {showAllFavouritesCall} from "@/services/lessonService";
import ListFavourites from "@/components/views/profile/ListFavourites";

export default {
  name: 'Favourites',
  data() {
    return {
      listAllFavourite: [],
    }
  },
  components: {ListFavourites, ButtonPrimary, TitleH2, TitleH1},
  methods: {
    getAllFavourites(){
      showAllFavouritesCall().then(res => {
        this.listAllFavourite = res
      })

}

  },
  mounted() {
    this.getAllFavourites();
    this.$store.dispatch('setBackPage', {name: 'Profile'})
  },
  beforeUnmount() {
    this.$store.dispatch('resetBackPage')
  },
}
</script>
<style lang="scss" scoped>

</style>
